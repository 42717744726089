import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../../redux/user/user.actions';
import clientConfig from '../../../env';
import {toast } from 'react-toastify';
import { FaMale, FaFemale} from 'react-icons/fa'
import moment from 'moment';
import { uuid } from 'uuidv4';

// Routes
import { Link, useHistory } from 'react-router-dom';

// Icons
import { IoMdClose } from 'react-icons/io';
import { FiEyeOff, FiEye } from "react-icons/fi";
import { generateEncryptKey } from '../../../utilities/utilities';
import { timezoneOptionList } from '../../schedule/settings/settings.constant';

const SelectOption = ({ rangeStart, rangeEnd, convertTo, sorting, ...otherProps}) => {
    let options = [];
    let months = [ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ];
    for(let i = rangeStart; i <= rangeEnd; i++){
        if(convertTo === 'month'){
            options.push(<option key={i} value={i < 10 ? `0${i}` : i}>{months[i - 1]}</option>);
        } else if(sorting === 'desc'){
            options.unshift(<option key={i} value={i}>{i}</option>);
        } else {
            options.push(<option key={i} value={i}>{i}</option>);
        }
    }
    return options;
};

const SignUp = ({
  registerVisible,
  sendDataToParent,
  closeVisible = true,
  invitekey = null,
  inviterName = null,
  useremail = "",
}) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState(useremail);
  const [phone, setPhone] = useState("");
  // const [username, setUsername] = useState('');
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [interestedIn, setInterestedIn] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [myState, setMyState] = useState("");
  const [country, setCountry] = useState("United States of America");
  const [zipcode, setZipcode] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [locationData, setLocationData] = useState(null);

  const [errormsg, setErrormsg] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const [requestProccessingText, setRequestProccessingText] = useState();

  const inviteCode = invitekey ? { inviteCode: invitekey } : null;

  // Function to get the user's geolocation
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude)
          setLocation({ latitude, longitude });
          fetchLocationData(latitude, longitude);
        },
        (error) => {
          setError("Unable to retrieve location. Please allow location access.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  // Fetch location details from reverse geocoding API
  const fetchLocationData = async (latitude, longitude) => {
    try {
      const APIKEY= "cc4f16050488464d96501257aa13d396";
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${APIKEY}`
      );
      const data = response.data.results[0];
      setLocationData({
        country: data.components.country,
        city: data.components.city || data.components.town || data.components.village,
      });

      setCountry(data.components.country);
      setCity(data.components.county || data.components.town || data.components.village);
      setMyState(data.components.state)

      console.log(data.components.postcode)
    } catch (err) {
      setError("Unable to fetch location details.");
    }
  };

  const generateProfileKey = () => {
    const firstName = firstname
      .toLocaleLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "");
    const lastName = lastname
      .toLocaleLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "");
    const ramdomString = Math.random().toString(36).slice(2, 7);

    return `${firstName}-${lastName}-${ramdomString}`
  }

  generateProfileKey()

  const getDefaultTimeZone = () => {
      const currentTimezone = moment.tz.guess();
      let defaultTimezoneOption = null;

      for(let i = 0; i < timezoneOptionList.length; i++) {
        if (
          timezoneOptionList[i].options.length
        ) {
          const found = timezoneOptionList[i].options.find(
            (option) => option.value === currentTimezone
          );
          defaultTimezoneOption = found;
        }

        if(defaultTimezoneOption) {
          break;
        }
      }
      return defaultTimezoneOption;
  }


  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    const register_data = {
      username: email,
      email: email,
      password: password,
      first_name: firstname,
      last_name: lastname,
      phone: phone,
      birthday: `${birthYear}${birthMonth}${birthdate}`,
      gender: gender,
      interested_in: interestedIn,
      my_credits: 18,
      city: city,
      state: myState,
      country: country,
      zip: zipcode,
      hostURL: window.location.origin,
      calendarConnected: false,
      profile_key: generateProfileKey(),
      secretkey: generateEncryptKey(email.toLowerCase()),
      scheduleMeetingSettings: JSON.stringify({
        timeZone: getDefaultTimeZone(),
        durations: [
          {
            label: "15 min",
            value: 15,
          },
          {
            label: "30 min",
            value: 30,
          },
          {
            label: "1 hr",
            value: 1,
          },
        ],
        communications: [
          {
            label: "Phone",
            value: "phone",
            index: 0,
          },
          {
            label: "Video",
            value: "video",
            index: 1,
          },
          {
            label: "In-Person",
            value: "in-person",
            index: 2,
          },
        ],
        minimumNoticeTime: {
          label: "15 minutes",
          value: "15_minutes",
        },
        availabilitywindow: [
          {
            id: uuid(),
            selecteDay: "1",
            startTime: "9:00",
            endTime: "17:00",
          },
          {
            id: uuid(),
            selecteDay: "2",
            startTime: "9:00",
            endTime: "17:00",
          },
          {
            id: uuid(),
            selecteDay: "3",
            startTime: "9:00",
            endTime: "17:00",
          },
          {
            id: uuid(),
            selecteDay: "4",
            startTime: "9:00",
            endTime: "17:00",
          },
          {
            id: uuid(),
            selecteDay: "5",
            startTime: "9:00",
            endTime: "17:00",
          },
        ],
      }),
      ...inviteCode,
    };
    setRequestProccessingText("...signing up");

    axios
      .post(`${clientConfig.siteUrl}wl/v1/register`, register_data)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          setRequestProccessingText("...please wait");
          onLoginFormSubmit();
        }

        if (response.data.code === 400) {
          setErrormsg(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const onLoginFormSubmit = () => {
    const loginData = {
      username: email,
      password: password,
    };
    axios
      .post(`${clientConfig.siteUrl}jwt-auth/v1/token`, loginData)
      .then((response) => {
        let data = response.data.data;

        if (undefined === data.token) {
          return;
        }
        setRequestProccessingText("...getting started");
        let loginInfo = { token: data.token };
        axios
          .get(`${clientConfig.siteUrl}wl/v1/user/${data.id}`)
          .then((response) => {
            let userInfoData = response.data;
            let mergedata = { ...loginInfo, ...userInfoData };
            dispatch(setCurrentUser(mergedata));
            setLoggedIn(true);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      })
      .catch((error) => {
        toast.error(error.response);
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  // Call getLocation() when the component mounts
  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      setRequestProccessingText("");
      history.push("/dashboard");
    }
  }, [history, loggedIn]);

  return (
    <React.Fragment>
      <div className="popup-backdrop"></div>
      <div className="popup-container">
        <div className="card">
          <div className="card-header bg-transparent">
            {closeVisible && (
              <button
                type="button"
                className="btn close-icon"
                onClick={() => sendDataToParent(!registerVisible)}
              >
                <IoMdClose />
              </button>
            )}
            <h2 className="h3">Sign Up</h2>
            <p className="mb-0">Join 3For3 Networking</p>
            {invitekey && (
              <p className="mt-2 mb-0">
                You are registering using an invite code from{" "}
                <b className="text-capitalize">{inviterName}</b>
              </p>
            )}
          </div>
          <div className="card-body">
            <form onSubmit={handleRegisterSubmit} className="text-center">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="First name *"
                    onChange={(e) => setFirstname(e.target.value)}
                    value={firstname}
                    disabled={!!requestProccessingText}
                    maxLength={100}
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="Last name *"
                    onChange={(e) => setLastname(e.target.value)}
                    value={lastname}
                    disabled={!!requestProccessingText}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  required={true}
                  className="form-control"
                  placeholder="Email *"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  disabled={!!requestProccessingText}
                  maxLength={320}
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  required={true}
                  className="form-control"
                  placeholder="Phone *"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  pattern="[0-9.]+"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  minLength={8}
                  maxLength={10}
                  disabled={!!requestProccessingText}
                />
              </div>
              {/* <div className="form-group">
                            <input type="text" className="form-control" placeholder="Username" onChange={ (e) => setUsername(e.target.value)} value={username}/>
                        </div> */}
              <div className="form-group">
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    required={true}
                    className="form-control"
                    placeholder="Password *"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    disabled={!!requestProccessingText}
                  />
                  <div
                    className="input-group-prepend"
                    onClick={handleShowPassword}
                  >
                    <div
                      className="border-left-0 input-group-text"
                      style={{ borderRadius: "0 3px 3px 0", cursor: "pointer" }}
                    >
                      {!showPassword ? <FiEyeOff /> : <FiEye />}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="form-group">
                            <select name="interested_in" required={true} className="form-control" onChange={ (e) => setInterestedIn(e.target.value)} value={interestedIn}>
                                <option value="">Interested In *</option>
                                <option value="Mentoring">Mentoring</option>
                                <option value="Networking">Networking</option>
                                <option value="Business">Business</option>
                            </select>
                        </div> */}

              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="City *"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    disabled={!!requestProccessingText}
                    maxLength={50}
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="State *"
                    onChange={(e) => setMyState(e.target.value)}
                    value={myState}
                    disabled={!!requestProccessingText}
                    maxLength={50}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="Zipcode *"
                    onChange={(e) => setZipcode(e.target.value)}
                    value={zipcode}
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    minLength={4}
                    maxLength={6}
                    disabled={!!requestProccessingText}
                  />
                </div>
                <div className="form-group col-md-6">
                  <select
                    className="form-control"
                    id="country"
                    name="country"
                    value={country}
                    required={true}
                    onChange={(e) => setCountry(e.target.value)}
                    style={{ paddingLeft: "8px", paddingRight: "8px" }}
                    disabled={!!requestProccessingText}
                  >
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </option>
                    <option value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="form-row">
                <label className="col-12 text-left small-text mb-1">
                  Birthday *
                </label>
                <div className="form-group col col-md-4">
                  <select
                    name="month"
                    required={true}
                    id="bmonth"
                    className="form-control"
                    onChange={(e) => setBirthMonth(e.target.value)}
                    value={birthMonth}
                    disabled={!!requestProccessingText}
                  >
                    <option value="">Month</option>
                    <SelectOption
                      rangeStart="1"
                      rangeEnd="12"
                      convertTo="month"
                    />
                  </select>
                </div>

                <div className="form-group col-3 col-md-4">
                  <select
                    name="day"
                    required={true}
                    id="bday"
                    className="form-control"
                    onChange={(e) => setBirthdate(e.target.value)}
                    value={birthdate}
                    disabled={!!requestProccessingText}
                  >
                    <option value="">Day</option>
                    <SelectOption rangeStart="1" rangeEnd="31" />
                  </select>
                </div>

                <div className="form-group col col-md-4">
                  <select
                    name="year"
                    required={true}
                    id="byear"
                    className="form-control"
                    onChange={(e) => setBirthYear(e.target.value)}
                    value={birthYear}
                    disabled={!!requestProccessingText}
                  >
                    <option value="">Year</option>
                    <SelectOption
                      rangeStart="1950"
                      rangeEnd={new Date().getFullYear()}
                      sorting="desc"
                    />
                  </select>
                </div>
              </div>

              <div className="form-row">
                <label className="col-12 text-left small-text mb-1">
                  Gender *
                </label>
                <div className="form-group col-6">
                  <label
                    className={`form-check-label btn btn-outline-secondary w-100 d-flex align-items-center justify-content-between ${
                      !!requestProccessingText
                        ? "form-check-label-disabled"
                        : ""
                    }`}
                    htmlFor="radiomale"
                  >
                    <span>
                      <FaMale className="mr-1" />
                      Male
                    </span>
                    <input
                      required={true}
                      type="radio"
                      name="gender"
                      id="radiomale"
                      value="Male"
                      onChange={(e) => setGender(e.target.value)}
                      checked={gender === "Male" ? true : false}
                      disabled={!!requestProccessingText}
                    />
                  </label>
                </div>

                <div className="form-group col-6">
                  <label
                    className={`form-check-label btn btn-outline-secondary w-100 d-flex align-items-center justify-content-between ${
                      !!requestProccessingText
                        ? "form-check-label-disabled"
                        : ""
                    }`}
                    htmlFor="radiofemale"
                  >
                    <span>
                      <FaFemale className="mr-1" />
                      Female
                    </span>
                    <input
                      required={true}
                      type="radio"
                      name="gender"
                      id="radiofemale"
                      value="Female"
                      onChange={(e) => setGender(e.target.value)}
                      checked={gender === "Female" ? true : false}
                      disabled={!!requestProccessingText}
                    />
                  </label>
                </div>

                {/* <div className="form-group col-md-4">
                                <label className="form-check-label btn btn-outline-secondary w-100 d-flex align-items-center justify-content-between" htmlFor="radioother">
                                    Other
                                    <input required={true} type="radio" name="gender" id="radioother" value="Other" onChange={ (e) => setGender(e.currentTarget.value) } checked={ gender === 'Other' ? true : false }/>
                                </label>
                            </div> */}
              </div>

              <p style={{ lineHeight: "15px", textAlign: "left" }}>
                <small>
                  By clicking Sign Up, you agree to our{" "}
                  <Link
                    to="/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms &amp; Conditions
                  </Link>
                  . You may receive email notifications from us and you may opt
                  out of receiving them at any time.
                </small>
              </p>

              <button
                type="submit"
                disabled={!!requestProccessingText}
                className="mt-3 mt-md-4 btn btn-lg btn-success px-5"
              >
                {requestProccessingText ? requestProccessingText : "Sign Up"}
              </button>

              {errormsg && (
                <>
                  <p className="mt-3 mb-0">
                    <span className="text-danger">
                      This email is already in use.
                    </span>
                  </p>
                  <p>
                    <span
                      className="text-primary loginBtn"
                      onClick={() => sendDataToParent(!registerVisible)}
                    >
                      Login To Your Account
                    </span>{" "}
                    or <Link to="/forgot-password">Reset Your Password</Link>.
                  </p>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;