import React from 'react';
import "./stepsform.styles.scss";
import axios from 'axios';
import { toast } from 'react-toastify';
import clientConfig from '../../env';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';

import Introduction from './introduction.components'
import UserDetails from './userDetails.components';
import EducationDetails from './educationdetails.components';
import HowItWorks from './howitworks.components';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { GrFormClose } from 'react-icons/gr'
import { generateEncryptKey } from '../../utilities/utilities';


const Logo = () => {
    return (
        <div className='text-center mb-4'>
            <h2 style={{ fontSize: "clamp(18px, 5vw, 25px)", color: "#000000" }} className="navbar-brand">3For3 <span>Networking</span></h2>
        </div>
    )
}

const StepsTracker = ({currentstep}) => {
    return (
        <ul className='step-tracker'>
            <li className={currentstep === 1 && "active"}><span>Step 1</span></li>
            <li className={currentstep === 2 && "active"}><span>Step 2</span></li>
            <li className={currentstep === 3 && "active"}><span>Step 3</span></li>
            <li className={currentstep === 4 && "active"}><span>Step 4</span></li>
        </ul>
    )
}

class StepsForm extends React.Component {

    state = {
        step: 1,
        headline: this.props.userInfo.headline,
        company: this.props.userInfo.organization,
        designation: this.props.userInfo.organization_title,
        degree: this.props.userInfo.university_degree,
        school: this.props.userInfo.university,
        industry: this.props.userInfo.industry,
        interestedIn: this.props.userInfo.interested_in,
        classification: this.props.userInfo.classification,
        linkedinUrl: this.props.userInfo.url,
        about: this.props.userInfo.about || "",
        status: this.props.userInfo.status,
        email_verified: this.props.userInfo.email_verified,
        using_invite_code: this.props.userInfo.using_invite_code,
        inviteCode: this.props.userInfo.inviteCode,
        inviteCredit: this.props.userInfo.inviteCredit,
        checked: false
    }

    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
        this.state.email_verified !== "" && this.getLatestData();
    }

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
        this.state.email_verified !== "" && this.getLatestData();
    }

    // handle field change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }

    handleCheckbox = () => {
        this.setState({
            checked: !this.state.checked
        }, () => {
            if (this.state.checked) {
                this.setState({
                    company: "Self Employed"
                })
            } else {
                this.setState({
                    company: ""
                })
            }
        } )
    }

    componentDidMount = () => {
        this.getLatestData();
        if (this.props.userInfo.status !== 'Inactive') {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }

    getLatestData = () => {
        axios.get(`${clientConfig.siteUrl}wl/v1/user/${this.props.userInfo.userId}`)
        .then(res => {
            this.setState({
                email_verified: res.data.email_verified
            })
            let loginInfo = { token: this.props.userInfo.token }
            this.props.setcurrentUser({ ...loginInfo, ...res.data })
        }).catch(err => console.error(err));
    }

    savefinalData = (e) => {
        e.preventDefault();
        const data = {
            "url": this.state.linkedinUrl,
            "status": "Active",
            "interested_in": this.state.interestedIn,
            "industry": this.state.industry,
            "classification": this.state.classification,
            "headline": this.state.headline,
            "organization": this.state.company,
            "organization_title": this.state.designation,
            "university": this.state.school,
            "university_degree": this.state.degree,
            "description": this.state.about,
            "using_invite_code": this.state.inviteCode ? 1 : this.state.using_invite_code,
            "inviteCode": this.state.inviteCode,
            "steps_form_complete": 1,
        }

        const configheader = {
            "Content-Type": 'application/json',
            "Authorization": `Barer ${this.props.userInfo.token}`,
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/user/${this.props.userInfo.userId}`, data, {
            headers: configheader
        }).then(response => {
            if(response.status === 200) {
                toast.success(response.data.message);
                if(this.state.inviteCode) {
                    this.addInviteCredit();
                } else {
                    this.getLatestData();
                }
            }
        }).catch(error => {
            toast.error(error.response);
        });
    }

    addInviteCredit = () => {
        const httpbody = {
            "profile_key": this.state.inviteCode,
            "credit": 20
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/add-invite-credit`, httpbody)
        .then( response => {
            this.getLatestData();
        }).catch(error => {
            console.log(error);
        });
    }

    resendVerification = () => {

        const httpbody = {
          email: this.props.userInfo.email,
          user_id: this.props.userInfo.userId,
          username: `${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`,
          hostURL: window.location.origin,
          secretkey: generateEncryptKey(
            this.props.userInfo.email.toLowerCase()
          ),
        };

        const configheader = {
            "Content-Type": 'application/json',
            "Authorization": `Barer ${this.props.userInfo.token}`,
        }

        axios.post(`${clientConfig.siteUrl}wl/v1/resendverify`, httpbody ,{
            headers: configheader
        })
        .then( response =>  {
            if(response.data.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }).catch( error => {
            toast.error(error.response);
        })
    }

    render() {
        
        const { headline, company, designation, degree, school, industry, interestedIn, classification, linkedinUrl, about, email_verified } = this.state;
        const values = { headline, company, designation, degree, school, industry, interestedIn, classification, linkedinUrl, about, email_verified };
        
        const { step } = this.state;
        switch (step) {
            case 1:
                return (
                    <div className="stepsform-container">
                        {/* {email_verified === undefined || email_verified === null &&
                            <NotificationMessage resendVerification={this.resendVerification} />
                        } */}
                        <div className="col-12 col-md-8 offset-md-2 formContainer">
                            <Logo />
                            <StepsTracker currentstep={step}/>
                            <Introduction 
                                nextStep={ this.nextStep}
                                email_verified={email_verified}
                            />
                        </div>
                    </div>             
                )
            case 2:
                return (
                    <div className="stepsform-container">
                        {/* {email_verified === undefined || email_verified === null && 
                            <NotificationMessage resendVerification={this.resendVerification} />
                        } */}
                        <div className="col-12 col-md-8 offset-md-2 formContainer">
                            <Logo />
                            <StepsTracker currentstep={step} />
                            <UserDetails
                                prevStep={this.prevStep}
                                nextStep={this.nextStep}
                                handleChange={this.handleChange}
                                values={values}
                                token={this.props.userInfo.token}
                                userId={this.props.userInfo.userId}
                                username={`${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`}
                                bannerImg={this.props.userInfo.profile_banner}
                                profileImg={this.props.userInfo.profile_picture}
                                getLatestData={this.getLatestData}
                                handleCheckbox={this.handleCheckbox}
                                checked={this.state.checked}
                            />
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="stepsform-container">
                        {/* {email_verified === undefined || email_verified === null &&
                            <NotificationMessage resendVerification={this.resendVerification} />
                        } */}
                        <div className="col-12 col-md-8 offset-md-2 formContainer">
                            <Logo />
                            <StepsTracker currentstep={step}/>
                            <EducationDetails
                                prevStep={this.prevStep}
                                nextStep={this.nextStep}
                                handleChange={this.handleChange}
                                values={values}
                            />
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="stepsform-container">
                        {/* {email_verified === undefined || email_verified === null &&
                            <NotificationMessage resendVerification={this.resendVerification} />
                        } */}
                        <div className="col-12 col-md-8 offset-md-2 formContainer">
                            <Logo />
                            <StepsTracker currentstep={step}/>
                            <HowItWorks
                                prevStep={this.prevStep}
                                savefinalData={this.savefinalData}
                                email_verified={email_verified}
                            />
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="stepsform-container">
                        <div className="col-12 col-md-6 offset-md-3 formContainer">
                            <h2>Default</h2>
                        </div>
                    </div>
                )
        }
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.user,
});

const mapDispatchToProps = (dispatch) => {
    return ({
        setcurrentUser: (user) => dispatch(setCurrentUser(user))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(StepsForm);

const NotificationMessage = ({resendVerification}) => {
    return(
        <div className="alert sticky-top alert-dismissible  alert-warning d-flex align-items-center justify-content-center" role="alert">
            <RiAlarmWarningLine className='mr-3' color='#f00' size="1.5em" />
            <div style={{ color: "#212529" }}>Please verify your account by clicking the activation link that has been sent to your email or <div style={{ display: "inline-block", cursor: "pointer" }} className="text-primary" onClick={resendVerification}>resend verification email</div>.</div>
        </div>
    )
}